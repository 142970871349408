<template>
	<create-user
		:edit-user="true"
		tittle-form="Editar Usuario"
		:id-user="userId"
	/>
</template>

<script>
	import createUser from '../../components/adminUsers/createUser.vue';
	export default {
		components: { createUser },
		name: 'EditUser',
		title: 'Info Feed | Infoguia',
		props: {
			userId: {
				required: true,
			},
		},
		created() {},
	};
</script>

<style></style>
